<template>
  <div class="data">
    <div class="data__content">
      <h2 class="data__heading">Datenschutzhinweise</h2>
      <h3 class="data__subheading">Datenschutz</h3>
      Wir haben diese Datenschutzerklärung (Fassung 20.04.2020-311170952)
      verfasst, um Ihnen gemäß der Vorgaben der
      <a
        href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679&tid=311171647"
        target="_blank"
        rel="nofollow"
        >Datenschutz-Grundverordnung (EU) 2016/679</a
      >
      zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und
      welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.
      <br /><br />
      Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr
      technisch klingen, wir haben uns bei der Erstellung jedoch bemüht die
      wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.
      <h3 class="data__subheading">Automatische Datenspeicherung</h3>
      <p>
        Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen
        automatisch erstellt und gespeichert, so auch auf dieser Webseite.
      </p>
      <p>
        Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser
        Webserver (Computer auf dem diese Webseite gespeichert ist) automatisch
        Daten wie
      </p>
      <ul>
        <li>die Adresse (URL) der aufgerufenen Webseite</li>
        <li>Browser und Browserversion</li>
        <li>das verwendete Betriebssystem</li>
        <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
        <li>
          den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen
          wird
        </li>
        <li>Datum und Uhrzeit</li>
      </ul>
      <p>in Dateien (Webserver-Logfiles).</p>

      <p>
        In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und
        danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können
        jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
        rechtswidrigem Verhalten eingesehen werden.
      </p>
      <h3 class="data__subheading">Cookies</h3>
      Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu
      speichern. <br />
      Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden,
      damit Sie die folgende Datenschutzerklärung besser verstehen.
      <h4 class="data__subsubheading">Was genau sind Cookies?</h4>
      <p>
        Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser.
        Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet
        Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine
        Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.
      </p>
      <p>
        Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche
        Helferlein. Fast alle Webseiten verwenden Cookies. Genauer gesprochen
        sind es HTTP-Cookies, da es auch noch andere Cookies für andere
        Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von
        unserer Website auf Ihrem Computer gespeichert werden. Diese
        Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem “Hirn”
        Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und
        einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder
        mehrere Attribute angegeben werden.
      </p>
      <p>
        Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise
        Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite
        wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“
        Informationen an unsere Seite zurück. Dank der Cookies weiß unsere
        Website, wer Sie sind und bietet Ihnen Ihre gewohnte
        Standardeinstellung. In einigen Browsern hat jedes Cookie eine eigene
        Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer
        einzigen Datei gespeichert.
      </p>
      <p>
        Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies.
        Erstanbieter-Cookies werden direkt von unserer Seite erstellt,
        Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google
        Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes
        Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies
        variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind
        keine Software-Programme und enthalten keine Viren, Trojaner oder andere
        „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs
        zugreifen.
      </p>
      So können zum Beispiel Cookie-Daten aussehen:
      <ul>
        <li>Name: _ga</li>
        <li>Ablaufzeit: 2 Jahre</li>
        <li>Verwendung: Unterscheidung der Webseitenbesucher</li>
        <li>Beispielhafter Wert: GA1.2.1326744211.152311170952</li>
      </ul>
      Ein Browser sollte folgende Mindestgrößen unterstützen:
      <ul>
        <li>Ein Cookie soll mindestens 4096 Bytes enthalten können</li>
        <li>
          Pro Domain sollen mindestens 50 Cookies gespeichert werden können
        </li>
        <li>
          Insgesamt sollen mindestens 3000 Cookies gespeichert werden können
        </li>
      </ul>
      <h4 class="data__subsubheading">Welche Arten von Cookies gibt es?</h4>
      <p>
        Die Frage welche Cookies wir im Speziellen verwenden, hängt von den
        verwendeten Diensten ab und wird in der folgenden Abschnitten der
        Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die
        verschiedenen Arten von HTTP-Cookies eingehen.
      </p>
      <p>
        Man kann 4 Arten von Cookies unterscheiden:
      </p>
      <p>
        <span class="bold-heading">
          Unbedingt notwendige Cookies
        </span>
        Diese Cookies sind nötig, um grundlegende Funktionen der Website
        sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User
        ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft
        und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb
        nicht gelöscht, selbst wenn der User sein Browserfenster schließt.
      </p>
      <p>
        <span class="bold-heading">
          Funktionelle Cookies
        </span>
        Diese Cookies sammeln Infos über das Userverhalten und ob der User
        etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies
        auch die Ladezeit und das Verhalten der Website bei verschiedenen
        Browsern gemessen.
      </p>
      <p>
        <span class="bold-heading">
          Zielorientierte Cookies
        </span>
        Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit.
        Beispielsweise werden eingegebene Standorte, Schriftgrößen oder
        Formulardaten gespeichert.
      </p>
      <p>
        <span class="bold-heading">
          Werbe-Cookies
        </span>
        Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem
        User individuell angepasste Werbung zu liefern. Das kann sehr praktisch,
        aber auch sehr nervig sein.
      </p>
      Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt,
      welche dieser Cookiearten Sie zulassen möchten. Und natürlich wird diese
      Entscheidung auch in einem Cookie gespeichert.
      <h4 class="data__subsubheading">Wie kann ich Cookies löschen?</h4>
      <p>
        Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst.
        Unabhängig von welchem Service oder welcher Website die Cookies stammen,
        haben Sie immer die Möglichkeit Cookies zu löschen, nur teilweise
        zuzulassen oder zu deaktivieren. Zum Beispiel können Sie Cookies von
        Drittanbietern blockieren, aber alle anderen Cookies zulassen.
      </p>
      <p>
        Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser
        gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen
        wollen, können Sie dies in Ihren Browser-Einstellungen finden:
      </p>
      <a
        href="https://support.google.com/chrome/answer/95647?tid=311170952"
        target="_blank"
        rel="nofollow"
        >Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a
      >
      <br />
      <br />
      <a
        href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311170952"
        target="_blank"
        rel="nofollow"
        >Safari: Verwalten von Cookies und Websitedaten mit Safari</a
      >
      <br />
      <br />
      <a
        href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311170952"
        target="_blank"
        rel="nofollow"
        >Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem
        Computer abgelegt haben</a
      >
      <br />
      <br />
      <a
        href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311170952"
        target="_blank"
        rel="nofollow"
        >Internet Explorer: Löschen und Verwalten von Cookies</a
      >
      <br />
      <br />
      <a
        href="https://support.microsoft.com/de-at/help/4027947/microsoft-edge-delete-cookies?tid=311170952"
        target="_blank"
        rel="nofollow"
        >Microsoft Edge: Löschen und Verwalten von Cookies</a
      >
      <br />
      <br />
      <p>
        Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
        Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
        gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
        entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise
        ist je nach Browser verschieden. Am besten ist es Sie suchen die
        Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder
        “Cookies deaktivieren Chrome” im Falle eines Chrome Browsers oder
        tauschen das Wort “Chrome” gegen den Namen Ihres Browsers, z.B. Edge,
        Firefox, Safari aus.
      </p>
      <h4 class="data__subsubheading">
        Wie sieht es mit meinem Datenschutz aus?
      </h4>
      <p>
        Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist
        festgehalten, dass das Speichern von Cookies eine Einwilligung des
        Website-Besuchers (also von Ihnen) verlangt. Innerhalb der EU-Länder
        gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese
        Richtlinien. In Deutschland wurden die Cookie-Richtlinien nicht als
        nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung dieser
        Richtlinie weitgehend in § 15 Abs.3 des Telemediengesetzes (TMG).
      </p>
      <p>
        Wenn Sie mehr über Cookies wissen möchten und vor technischen
        Dokumentationen nicht zurückscheuen, empfehlen wir
        <a
          href="https://tools.ietf.org/html/rfc6265"
          target="_blank"
          rel="nofollow"
          >https://tools.ietf.org/html/rfc6265,</a
        >
        dem Request for Comments der Internet Engineering Task Force (IETF)
        namens “HTTP State Management Mechanism”.
      </p>
      <h3 class="data__subheading">Speicherung persönlicher Daten</h3>
      <p>
        Persönliche Daten, die Sie uns auf dieser Website elektronisch
        übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere
        persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder
        Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der
        IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt
        und nicht an Dritte weitergegeben.
      </p>
      <p>
        Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit
        jenen Besuchern, die Kontakt ausdrücklich wünschen und für die
        Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und
        Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht
        weiter, können jedoch nicht ausschließen, dass diese Daten beim
        Vorliegen von rechtswidrigem Verhalten eingesehen werden.
      </p>
      <p>
        Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits
        dieser Webseite – können wir keine sichere Übertragung und den Schutz
        Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals
        unverschlüsselt per E-Mail zu übermitteln.
      </p>
      <p>
        Die Rechtsgrundlage besteht nach
        <a
          href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=311170952"
          target="_blank"
          rel="nofollow"
          >Artikel 6 Absatz 1 a DSGVO</a
        >
        (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die Einwilligung
        zur Verarbeitung der von Ihnen eingegebenen Daten geben. Sie können
        diesen Einwilligung jederzeit widerrufen – eine formlose E-Mail reicht
        aus, Sie finden unsere Kontaktdaten im Impressum.
      </p>
      <h3 class="data__subheading">Rechte laut Datenschutzgrundverordnung</h3>
      Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende
      Rechte zu:
      <ul>
        <li>Recht auf Berichtigung (Artikel 16 DSGVO)</li>
        <li>
          Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)
        </li>
        <li>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
        <li>
          Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit
          der Berichtigung oder Löschung personenbezogener Daten oder der
          Einschränkung der Verarbeitung (Artikel 19 DSGVO)
        </li>
        <li>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
        <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
        <li>
          Recht, nicht einer ausschließlich auf einer automatisierten
          Verarbeitung — einschließlich Profiling — beruhenden Entscheidung
          unterworfen zu werden (Artikel 22 DSGVO)
        </li>
      </ul>
      <p>
        Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das
        Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche
        sonst in einer Weise verletzt worden sind, können Sie sich an die
        <a
          href="https://www.bfdi.bund.de/DE/Home/home_node.html"
          target="_blank"
          rel="nofollow"
          >Bundesbeauftragte für den Datenschutz und die Informationsfreiheit
          (BfDI)</a
        >
        wenden.
      </p>
      <h3 class="data__subheading">Auswertung des Besucherverhaltens</h3>
      <p>
        In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob
        und wie wir Daten Ihres Besuchs dieser Website auswerten. Die Auswertung
        der gesammelten Daten erfolgt in der Regel anonym und wir können von
        Ihrem Verhalten auf dieser Website nicht auf Ihre Person schließen.
      </p>
      <p>
        Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu
        widersprechen erfahren Sie in der folgenden Datenschutzerklärung.
      </p>
      <h3 class="data__subheading">TLS-Verschlüsselung mit https</h3>
      <p>
        Wir verwenden https um Daten abhörsicher im Internet zu übertragen
        (Datenschutz durch Technikgestaltung
        <a
          href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679&from=DE&tid=311170952"
          target="_blank"
          rel="nofollow"
          >Artikel 25 Absatz 1 DSGVO</a
        >). Durch den Einsatz von TLS (Transport Layer Security), einem
        Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet
        können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen
        die Benutzung dieser Absicherung der Datenübertragung am kleinen
        Schloßsymbol links oben im Browser und der Verwendung des Schemas https
        (anstatt http) als Teil unserer Internetadresse.
      </p>
      <h3 class="data__subheading">Google Analytics Datenschutzerklärung</h3>
      <p>
        Wir verwenden auf unserer Website das Analyse-Tracking Tool Google
        Analytics (GA) des amerikanischen Unternehmens Google Inc. Für den
        europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon
        House, Barrow Street Dublin 4, Irland) für alle Google-Dienste
        verantwortlich. Google Analytics sammelt Daten über Ihre Handlungen auf
        unserer Website. Wenn Sie beispielsweise einen Link anklicken, wird
        diese Aktion in einem Cookie gespeichert und an Google Analytics
        versandt. Mithilfe der Berichte, die wir von Google Analytics erhalten,
        können wir unsere Website und unser Service besser an Ihre Wünsche
        anpassen. Im Folgenden gehen wir näher auf das Tracking Tool ein und
        informieren Sie vor allem darüber, welche Daten gespeichert werden und
        wie Sie das verhindern können.
      </p>
      <h4 class="data__subsubheading">Was ist Google Analytics?</h4>
      <p>
        Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse
        unserer Website dient. Damit Google Analytics funktioniert, wird ein
        Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere
        Website besuchen, zeichnet dieser Code verschiedene Handlungen auf, die
        Sie auf unserer Website ausführen. Sobald Sie unsere Website verlassen,
        werden diese Daten an die Google-Analytics-Server gesendet und dort
        gespeichert.
      </p>
      Google verarbeitet die Daten und wir bekommen Berichte über Ihr
      Userverhalten. Dabei kann es sich unter anderem um folgende Berichte
      handeln:
      <ul>
        <li>
          Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User
          besser kennen und wissen genauer, wer sich für unser Service
          interessiert.
        </li>
        <li>
          Anzeigeberichte: Durch Anzeigeberichte können wir unsere Onlinewerbung
          leichter analysieren und verbessern.
        </li>
        <li>
          Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche
          Informationen darüber, wie wir mehr Menschen für unser Service
          begeistern können.
        </li>
        <li>
          Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website
          interagieren. Wir können nachvollziehen welchen Weg Sie auf unserer
          Seite zurücklegen und welche Links Sie anklicken.
        </li>
        <li>
          Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie
          aufgrund einer Marketing-Botschaft eine gewünschte Handlung ausführen.
          Zum Beispiel, wenn Sie von einem reinen Websitebesucher zu einem
          Käufer oder Newsletter-Abonnent werden. Mithilfe dieser Berichte
          erfahren wir mehr darüber, wie unsere Marketing-Maßnahmen bei Ihnen
          ankommen. So wollen wir unsere Conversionrate steigern.
        </li>
        <li>
          Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf
          unserer Website passiert. Zum Beispiel sehen wir wie viele User gerade
          diesen Text lesen.
        </li>
      </ul>
      <h4 class="data__subsubheading">
        Warum verwenden wir Google Analytics auf unserer Webseite?
      </h4>
      <p>
        Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das
        bestmögliche Service bieten. Die Statistiken und Daten von Google
        Analytics helfen uns dieses Ziel zu erreichen.
      </p>
      <p>
        Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den
        Stärken und Schwächen unserer Website. Einerseits können wir unsere
        Seite so optimieren, dass sie von interessierten Menschen auf Google
        leichter gefunden wird. Andererseits helfen uns die Daten, Sie als
        Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an
        unserer Website verbessern müssen, um Ihnen das bestmögliche Service zu
        bieten. Die Daten dienen uns auch, unsere Werbe- und Marketing-Maßnahmen
        individueller und kostengünstiger durchzuführen. Schließlich macht es
        nur Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen, die
        sich dafür interessieren.
      </p>
      <h4 class="data__subsubheading">
        Welche Daten werden von Google Analytics gespeichert?
      </h4>
      <p>
        Google Analytics erstellt mithilfe eines Tracking-Codes eine zufällige,
        eindeutige ID, die mit Ihrem Browser-Cookie verbunden ist. So erkennt
        Sie Google Analytics als neuen User. Wenn Sie das nächste Mal unsere
        Seite besuchen, werden Sie als „wiederkehrender“ User erkannt. Alle
        gesammelten Daten werden gemeinsam mit dieser User-ID gespeichert. So
        ist es überhaupt erst möglich pseudonyme Userprofile auszuwerten.
      </p>
      <p>
        Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre
        Interaktionen auf unserer Website gemessen. Interaktionen sind alle
        Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie
        auch andere Google-Systeme (wie z.B. ein Google-Konto) nützen, können
        über Google Analytics generierte Daten mit Drittanbieter-Cookies
        verknüpft werden. Google gibt keine Google Analytics-Daten weiter, außer
        wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen,
        wenn es gesetzlich erforderlich ist.
      </p>
      <p>
        Folgende Cookies werden von Google Analytics verwendet:
      </p>
      <p>
        <span class="label">Name:</span> _ga <br />
        <span class="label">Wert:</span> 2.1326744211.152311170952-5 <br />
        <span class="label">Verwendungszweck:</span> Standardmäßig verwendet
        analytics.js das Cookie _ga, um die User-ID zu speichern. Grundsätzlich
        dient es zur Unterscheidung der Webseitenbesucher. <br />
        <span class="label">Ablaufdatum:</span> nach 2 Jahren
      </p>
      <p>
        <span class="label">Name:</span> _gid <br />
        <span class="label">Wert:</span> 2.1687193234.152311170952-1 <br />
        <span class="label">Verwendungszweck:</span> Das Cookie dient auch zur
        Unterscheidung der Webseitenbesucher. <br />
        <span class="label">Ablaufdatum:</span> nach 24 Stunden
      </p>
      <p>
        <span class="label">Name:</span> _gat_gtag_UA_&lt;property-id&gt; <br />
        <span class="label">Wert:</span> 1 <br />
        <span class="label">Verwendungszweck:</span> Wird zum Senken der
        Anforderungsrate verwendet. Wenn Google Analytics über den Google Tag
        Manager bereitgestellt wird, erhält dieser Cookie den Namen _dc_gtm_
        &lt;property-id&gt;. <br />
        <span class="label">Ablaufdatum:</span> nach 1 Minute
      </p>
      <p>
        <span class="label">Name:</span> AMP_TOKEN <br />
        <span class="label">Wert:</span> keine Angaben <br />
        <span class="label">Verwendungszweck:</span> Das Cookie hat einen Token,
        mit dem eine User ID vom AMP-Client-ID-Dienst abgerufen werden kann.
        Andere mögliche Werte weisen auf eine Abmeldung, eine Anfrage oder einen
        Fehler hin. <br />
        <span class="label">Ablaufdatum:</span> nach 30 Sekunden bis zu einem
        Jahr
      </p>
      <p>
        <span class="label">Name:</span> __utma <br />
        <span class="label">Wert:</span> 1564498958.1564498958.1564498958.1n
        <br />
        <span class="label">Verwendungszweck:</span> Mit diesem Cookie kann man
        Ihr Verhalten auf der Website verfolgen und die Leistung messen. Das
        Cookie wird jedes Mal aktualisiert, wenn Informationen an Google
        Analytics gesendet werden. <br />
        <span class="label">Ablaufdatum:</span> nach 2 Jahren
      </p>
      <p>
        <span class="label">Name:</span> __utmt <br />
        <span class="label">Wert:</span> 1 <br />
        <span class="label">Verwendungszweck:</span> Das Cookie wird wie
        _gat_gtag_UA_&lt;property-id&gt; zum Drosseln der Anforderungsrate
        verwendet. <br />
        <span class="label">Ablaufdatum:</span> nach 10 Minuten
      </p>
      <p>
        <span class="label">Name:</span> __utmb <br />
        <span class="label">Wert:</span> 3.10.1564498958 <br />
        <span class="label">Verwendungszweck:</span> Dieses Cookie wird
        verwendet, um neue Sitzungen zu bestimmen. Es wird jedes Mal
        aktualisiert, wenn neue Daten bzw. Infos an Google Analytics gesendet
        werden. <br />
        <span class="label">Ablaufdatum:</span> nach 30 Minuten
      </p>
      <p>
        <span class="label">Name:</span> __utmc <br />
        <span class="label">Wert:</span> 167421564 <br />
        <span class="label">Verwendungszweck:</span> Dieses Cookie wird
        verwendet, um neue Sitzungen für wiederkehrende Besucher festzulegen.
        Dabei handelt es sich um ein Session-Cookie, und es wird nur solange
        gespeichert, bis Sie den Browser wieder schließen. <br />
        <span class="label">Ablaufdatum:</span> Nach Schließung des Browsers
      </p>
      <p>
        <span class="label">Name:</span> __utmz <br />
        <span class="label">Wert:</span>
        m|utmccn=(referral)|utmcmd=referral|utmcct=/ <br />
        <span class="label">Verwendungszweck:</span> Das Cookie wird verwendet,
        um die Quelle des Besucheraufkommens auf unserer Website zu
        identifizieren. Das heißt, das Cookie speichert, von wo Sie auf unsere
        Website gekommen sind. Das kann eine andere Seite bzw. eine
        Werbeschaltung gewesen sein. <br />
        <span class="label">Ablaufdatum:</span> nach 6 Monaten
      </p>
      <p>
        <span class="label">Name:</span> __utmv <br />
        <span class="label">Wert:</span> keine Angabe <br />
        <span class="label">Verwendungszweck:</span> Das Cookie wird verwendet,
        um benutzerdefinierte Userdaten zu speichern. Es wird immer
        aktualisiert, wenn Informationen an Google Analytics gesendet werden.
        <br />
        <span class="label">Ablaufdatum:</span> nach 2 Jahren
      </p>
      <p>
        <span class="label">Anmerkung:</span> Diese Aufzählung kann keinen
        Anspruch auf Vollständigkeit erheben, da Google die Wahl seiner Cookies
        immer wieder verändert.
      </p>
      <br />
      <p>
        Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die
        mit Google Analytics erhoben werden:
      </p>
      <p>
        <span class="label">Heatmaps:</span> Google legt sogenannte Heatmaps an.
        Über Heatmaps sieht man genau jene Bereiche, die Sie anklicken. So
        bekommen wir Informationen, wo Sie auf unserer Seite „unterwegs“ sind.
      </p>
      <p>
        <span class="label">Sitzungsdauer:</span> Als Sitzungsdauer bezeichnet
        Google die Zeit, die Sie auf unserer Seite verbringen, ohne die Seite zu
        verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung
        automatisch.
      </p>
      <p>
        <span class="label">Absprungrate</span> (engl. Bouncerate): Von einem
        Absprung ist die Rede, wenn Sie auf unserer Website nur eine Seite
        ansehen und dann unsere Website wieder verlassen.
      </p>
      <p>
        <span class="label">Kontoerstellung:</span> Wenn Sie auf unserer Website
        ein Konto erstellen bzw. eine Bestellung machen, erhebt Google Analytics
        diese Daten.
      </p>
      <p>
        <span class="label">IP-Adresse:</span> Die IP-Adresse wird nur in
        gekürzter Form dargestellt, damit keine eindeutige Zuordnung möglich
        ist.
      </p>
      <p>
        <span class="label">Standort:</span> Über die IP-Adresse kann das Land
        und Ihr ungefährer Standort bestimmt werden. Diesen Vorgang bezeichnet
        man auch als IP- Standortbestimmung.
      </p>
      <p>
        <span class="label">Technische Informationen:</span> Zu den technischen
        Informationen zählen unter anderem Ihr Browsertyp, Ihr Internetanbieter
        oder Ihre Bildschirmauflösung.
      </p>
      <p>
        <span class="label">Herkunftsquelle:</span> Google Analytics
        beziehungsweise uns, interessiert natürlich auch über welche Website
        oder welche Werbung Sie auf unsere Seite gekommen sind.
      </p>
      Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von
      Medien (z.B., wenn Sie ein Video über unsere Seite abspielen), das Teilen
      von Inhalten über Social Media oder das Hinzufügen zu Ihren Favoriten. Die
      Aufzählung hat keinen Vollständigkeitsanspruch und dient nur zu einer
      allgemeinen Orientierung der Datenspeicherung durch Google Analytics.
      <h4 class="data__subsubheading">
        Wie lange und wo werden die Daten gepeichert?
      </h4>
      <p>
        Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten Server
        befinden sich in Amerika und folglich werden Ihre Daten meist auf
        amerikanischen Servern gespeichert. Hier können Sie genau nachlesen wo
        sich die Google-Rechenzentren befinden:
        <a
          href="https://www.google.com/about/datacenters/inside/locations/?hl=de"
          target="_blank"
          rel="nofollow"
          >https://www.google.com/about/datacenters/inside/locations/?hl=de</a
        >
      </p>
      <p>
        Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt.
        Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor
        Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt
        es entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise
        die Hardware bei Google ausfällt oder Naturkatastrophen Server
        lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google
        dennoch gering.
      </p>
      <p>
        Standardisiert ist bei Google Analytics eine Aufbewahrungsdauer Ihrer
        Userdaten von 26 Monaten eingestellt. Dann werden Ihre Userdaten
        gelöscht. Allerdings haben wir die Möglichkeit, die Aufbewahrungsdauer
        von Nutzdaten selbst zu wählen. Dafür stehen uns fünf Varianten zur
        Verfügung:
      </p>
      <ul>
        <li>Löschung nach 14 Monaten</li>
        <li>Löschung nach 26 Monaten</li>
        <li>Löschung nach 38 Monaten</li>
        <li>Löschung nach 50 Monaten</li>
        <li>Keine automatische Löschung</li>
      </ul>
      <p>
        Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die
        Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre Daten, die mit
        Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der
        DoubleClick-Domain) verknüpft sind. Berichtergebnisse basieren auf
        aggregierten Daten und werden unabhängig von Nutzerdaten gespeichert.
        Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer
        größeren Einheit.
      </p>
      <h4 class="data__subsubheading">
        Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
      </h4>
      <p>
        Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht,
        Auskunft über Ihre Daten zu erhalten, sie zu aktualisieren, zu löschen
        oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung von
        Google Analytics-JavaScript (ga.js, analytics.js, dc.js) verhindern Sie,
        dass Google Analytics Ihre Daten verwendet. Das Browser-Add-on können
        Sie unter
        <a
          href="https://tools.google.com/dlpage/gaoptout?hl=de"
          target="_blank"
          rel="nofollow"
          >https://tools.google.com/dlpage/gaoptout?hl=de</a
        >
        runterladen und installieren. Beachten Sie bitte, dass durch dieses
        Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.
      </p>
      <p>
        Falls Sie grundsätzlich Cookies (unabhängig von Google Analytics)
        deaktivieren, löschen oder verwalten wollen, gibt es für jeden Browser
        eine eigene Anleitung:
      </p>
      <a
        href="https://support.google.com/chrome/answer/95647?tid=311170952"
        target="_blank"
        rel="nofollow"
        >Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a
      >
      <br />
      <br />
      <a
        href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311170952"
        target="_blank"
        rel="nofollow"
        >Safari: Verwalten von Cookies und Websitedaten mit Safari</a
      >
      <br />
      <br />
      <a
        href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311170952"
        target="_blank"
        rel="nofollow"
        >Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem
        Computer abgelegt haben</a
      >
      <br />
      <br />
      <a
        href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311170952"
        target="_blank"
        rel="nofollow"
        >Internet Explorer: Löschen und Verwalten von Cookies</a
      >
      <br />
      <br />
      <a
        href="https://support.microsoft.com/de-at/help/4027947/microsoft-edge-delete-cookies?tid=311170952"
        target="_blank"
        rel="nofollow"
        >Microsoft Edge: Löschen und Verwalten von Cookies</a
      >
      <br />
      <br />
      <p>
        Google Analytics ist aktiver Teilnehmer beim EU-U.S. Privacy Shield
        Framework, wodurch der korrekte und sichere Datentransfer persönlicher
        Daten geregelt wird. Mehr Informationen dazu finden Sie auf
        <a
          href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&tid=311170952"
          target="_blank"
          rel="nofollow"
          >https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&tid=311170952.</a
        >
        Wir hoffen wir konnten Ihnen die wichtigsten Informationen rund um die
        Datenverarbeitung von Google Analytics näherbringen. Wenn Sie mehr über
        den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden Links:
        <a
          href="http://www.google.com/analytics/terms/de.html"
          target="_blank"
          rel="nofollow"
          >http://www.google.com/analytics/terms/de.html</a
        >
        und
        <a
          href="https://support.google.com/analytics/answer/6004245?hl=de"
          target="_blank"
          rel="nofollow"
          >https://support.google.com/analytics/answer/6004245?hl=de.</a
        >
      </p>
      <h4 class="data__subsubheading">Google Analytics IP-Anonymisierung</h4>
      <p>
        Wir haben auf dieser Webseite die IP-Adressen-Anonymisierung von Google
        Analytics implementiert. Diese Funktion wurde von Google entwickelt,
        damit diese Webseite die geltenden Datenschutzbestimmungen und
        Empfehlungen der lokalen Datenschutzbehörden einhalten kann, wenn diese
        eine Speicherung der vollständigen IP-Adresse untersagen. Die
        Anonymisierung bzw. Maskierung der IP findet statt, sobald die
        IP-Adressen im Google Analytics-Datenerfassungsnetzwerk eintreffen und
        bevor eine Speicherung oder Verarbeitung der Daten stattfindet.
      </p>
      <p>
        Mehr Informationen zur IP-Anonymisierung finden Sie auf
        <a
          href="https://support.google.com/analytics/answer/2763052?hl=de"
          target="_blank"
          rel="nofollow"
          >https://support.google.com/analytics/answer/2763052?hl=de.</a
        >
      </p>
      <h4 class="data__subsubheading">
        Google Analytics Berichte zu demographischen Merkmalen und Interessen
      </h4>
      <p>
        Wir haben in Google Analytics die Funktionen für Werbeberichte
        eingeschaltet. Die Berichte zu demografischen Merkmalen und Interessen
        enthalten Angaben zu Alter, Geschlecht und Interessen. Damit können wir
        uns – ohne diese Daten einzelnen Personen zuordnen zu können – ein
        besseres Bild von unseren Nutzern machen. Mehr über die Werbefunktionen
        erfahren Sie auf
        <a
          href="https://support.google.com/analytics/answer/3450482?hl=de_AT&utm_id=ad"
          target="_blank"
          rel="nofollow"
          >https://support.google.com/analytics/answer/3450482?hl=de_AT&utm_id=ad.</a
        >
      </p>
      <p>
        Sie können die Nutzung der Aktivitäten und Informationen Ihres Google
        Kontos unter “Einstellungen für Werbung” auf
        <a
          href="https://adssettings.google.com/authenticated"
          target="_blank"
          rel="nofollow"
          >https://adssettings.google.com/authenticated</a
        >
        per Checkbox beenden.
      </p>
      <h3 class="data__subheading">Facebook-Pixel Datenschutzerklärung</h3>
      <p>
        Wir verwenden auf unserer Webseite das Facebook-Pixel von Facebook.
        Dafür haben wir einen Code auf unserer Webseite implementiert. Der
        Facebook-Pixel ist ein Ausschnitt aus JavaScript-Code, der eine
        Ansammlung von Funktionen lädt, mit denen Facebook Ihre Userhandlungen
        verfolgen kann, sofern Sie über Facebook-Ads auf unsere Webseite
        gekommen sind. Wenn Sie beispielsweise ein Produkt auf unserer Webseite
        erwerben, wird das Facebook-Pixel ausgelöst und speichert Ihre
        Handlungen auf unserer Webseite in einem oder mehreren Cookies. Diese
        Cookies ermöglichen es Facebook Ihre Userdaten (Kundendaten wie
        IP-Adresse, User-ID) mit den Daten Ihres Facebook-Kontos abzugleichen.
        Dann löscht Facebook diese Daten wieder. Die erhobenen Daten sind für
        uns anonym und nicht einsehbar und werden nur im Rahmen von
        Werbeanzeigenschaltungen nutzbar. Wenn Sie selbst Facebook-User sind und
        angemeldet sind, wird der Besuch unserer Webseite automatisch Ihrem
        Facebook-Benutzerkonto zugeordnet.
      </p>
      <p>
        Wir wollen unsere Dienstleistungen bzw. Produkte nur jenen Menschen
        zeigen, die sich auch wirklich dafür interessieren. Mithilfe von
        Facebook-Pixel können unsere Werbemaßnahmen besser auf Ihre Wünsche und
        Interessen abgestimmt werden. So bekommen Facebook-User (sofern sie
        personalisierte Werbung erlaubt haben) passende Werbung zu sehen.
        Weiters verwendet Facebook die erhobenen Daten zu Analysezwecken und
        eigenen Werbeanzeigen.
      </p>
      <p>
        Im Folgenden zeigen wir Ihnen jene Cookies, die durch das Einbinden von
        Facebook-Pixel auf einer Testseite gesetzt wurden. Bitte beachten Sie,
        dass dies nur Beispiel-Cookies sind. Je nach Interaktion auf unserer
        Webseite werden unterschiedliche Cookies gesetzt.
      </p>
      <p>
        <span class="label">Name:</span> _fbp <br />
        <span class="label">Wert:</span>
        fb.1.1568287647279.257405483-6311170952-7 <br />
        <span class="label">Verwendungszweck:</span> Dieses Cookie verwendet
        Facebook, um Werbeprodukte anzuzeigen. <br />
        <span class="label">Ablaufdatum:</span> nach 3 Monaten
      </p>
      <p>
        <span class="label">Name:</span> fr <br />
        <span class="label">Wert:</span> 0aPf312HOS5Pboo2r..Bdeiuf…1.0.Bdeiuf.
        <br />
        <span class="label">Verwendungszweck:</span> Dieses Cookie wird
        verwendet, damit Facebook-Pixel auch ordentlich funktioniert. <br />
        <span class="label">Ablaufdatum:</span> nach 3 Monaten
      </p>
      <p>
        <span class="label">Name:</span>
        comment_author_50ae8267e2bdf1253ec1a5769f48e062311170952-3 <br />
        <span class="label">Wert:</span> Name des Autors <br />
        <span class="label">Verwendungszweck:</span> Dieses Cookie speichert den
        Text und den Namen eines Users, der beispielsweise einen Kommentar
        hinterlässt. <br />
        <span class="label">Ablaufdatum:</span> nach 12 Monaten
      </p>
      <p>
        <span class="label">Name:</span>
        comment_author_url_50ae8267e2bdf1253ec1a5769f48e062 <br />
        <span class="label">Wert:</span> https%3A%2F%2Fwww.testseite…%2F (URL
        des Autors) <br />
        <span class="label">Verwendungszweck:</span> Dieses Cookie speichert die
        URL der Website, die der User in einem Textfeld auf unserer Webseite
        eingibt. <br />
        <span class="label">Ablaufdatum:</span> nach 12 Monaten
      </p>
      <p>
        <span class="label">Name:</span>
        comment_author_email_50ae8267e2bdf1253ec1a5769f48e062 <br />
        <span class="label">Wert:</span> E-Mail-Adresse des Autors <br />
        <span class="label">Verwendungszweck:</span> Dieses Cookie speichert die
        E-Mail-Adresse des Users, sofern er sie auf der Website bekannt gegeben
        hat. <br />
        <span class="label">Ablaufdatum:</span> nach 12 Monaten
      </p>
      <p>
        <span class="label">Anmerkung:</span> Die oben genannten Cookies
        beziehen sich auf ein individuelles Userverhalten. Speziell bei der
        Verwendung von Cookies sind Veränderungen bei Facebook nie
        auszuschließen.
      </p>
      <p>
        Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen
        für Werbeanzeigen unter
        <a
          href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
          target="_blank"
          rel="nofollow"
          >https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</a
        >
        selbst verändern. Falls Sie kein Facebook-User sind, können Sie auf
        <a
          href="http://www.youronlinechoices.com/de/praferenzmanagement/"
          target="_blank"
          rel="nofollow"
          >http://www.youronlinechoices.com/de/praferenzmanagement/</a
        >
        grundsätzlich Ihre nutzungsbasierte Online-Werbung verwalten. Dort haben
        Sie die Möglichkeit, Anbieter zu deaktivieren bzw. zu aktivieren.
      </p>
      <p>
        Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen,
        empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens auf
        <a
          href="https://www.facebook.com/policy.php"
          target="_blank"
          rel="nofollow"
          >https://www.facebook.com/policy.php.</a
        >
      </p>
      <h4 class="data__subsubheading">
        Facebook Automatischer erweiterter Abgleich Datenschutzerklärung
      </h4>
      <p>
        Wir haben im Rahmen der Facebook-Pixel-Funktion auch den automatischen
        erweiterten Abgleich (engl. Automatic Advanced Matching) aktiviert.
        Diese Funktion des Pixels ermöglicht uns, gehashte E-Mails, Namen,
        Geschlecht, Stadt, Bundesland, Postleitzahl und Geburtsdatum oder
        Telefonnummer als zusätzliche Informationen an Facebook zu senden,
        sofern Sie uns diese Daten zur Verfügung gestellt haben. Diese
        Aktivierung ermöglicht uns Werbekampagnen auf Facebook noch genauer auf
        Menschen, die sich für unsere Dienstleistungen oder Produkte
        interessieren, anzupassen.
      </p>
      <h3 class="data__subheading">Newsletter Datenschutzerklärung</h3>
      <p>
        Wenn Sie sich für unseren Newsletter eintragen übermitteln Sie die oben
        genannten persönlichen Daten und geben uns das Recht Sie per E-Mail zu
        kontaktieren. Die im Rahmen der Anmeldung zum Newsletter gespeicherten
        Daten nutzen wir ausschließlich für unseren Newsletter und geben diese
        nicht weiter.
      </p>
      <p>
        Sollten Sie sich vom Newsletter abmelden – Sie finden den Link dafür in
        jedem Newsletter ganz unten – dann löschen wir alle Daten die mit der
        Anmeldung zum Newsletter gespeichert wurden.
      </p>
      <h3 class="data__subheading">
        Google Ads (Google AdWords) Conversion-Tracking Datenschutzerklärung
      </h3>
      <p>
        Wir verwenden als Online-Marketing-Maßnahme Google Ads (früher Google
        AdWords), um unsere Produkte und Dienstleistungen zu bewerben. So wollen
        wir im Internet mehr Menschen auf die hohe Qualität unserer Angebote
        aufmerksam machen. Im Rahmen unserer Werbe-Maßnahmen durch Google Ads
        verwenden wir auf unserer Website das Conversion-Tracking der Firma
        Google Inc. In Europa ist allerdings für alle Google-Dienste das
        Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin
        4, Irland) verantwortlich. Mithilfe dieses kostenlosen Tracking-Tools
        können wir unser Werbeangebot an Ihre Interessen und Bedürfnisse besser
        anpassen. Im Folgenden Artikel wollen wir genauer darauf eingehen, warum
        wir Conversion-Tracking benutzen, welche Daten dabei gespeichert werden
        und wie Sie diese Datenspeicherung verhindern.
      </p>
      <h4 class="data__subsubheading">
        Was ist Google Ads Conversion-Tracking?
      </h4>
      <p>
        Google Ads (früher Google AdWords) ist das hauseigene Online-Werbesystem
        der Firma Google Inc. Wir sind von der Qualität unseres Angebots
        überzeugt und wollen, dass so viele Menschen wie möglich unsere Webseite
        kennenlernen. Im Onlinebereich bietet Google Ads dafür die beste
        Plattform. Natürlich wollen wir auch einen genauen Überblick über den
        Kosten-Nutzen-Faktor unsere Werbeaktionen gewinnen. Darum verwenden wir
        das Conversion-Tracking-Tool von Google Ads.
      </p>
      <p>
        Doch was ist eine Conversion eigentlich? Eine Conversion entsteht, wenn
        Sie von einem rein interessierten Webseitenbesucher zu einem handelnden
        Besucher werden. Dies passiert immer dann, wenn Sie auf unsere Anzeige
        klicken und im Anschluss eine andere Aktion ausführen, wie zum Beispiel
        unsere Webseite besuchen. Mit dem Conversion-Tracking-Tool von Google
        erfassen wir, was nach einem Klick eines Users auf unsere Google
        Ads-Anzeige geschieht. Zum Beispiel können wir so sehen, ob Produkte
        gekauft werden, Dienstleistungen in Anspruch genommen werden oder ob
        sich User für unseren Newsletter angemeldet haben.
      </p>
      <h4 class="data__subsubheading">
        Warum verwenden wir Google Ads Conversion-Tracking auf unserer Webseite?
      </h4>
      <p>
        Wir setzen Google Ads ein, um auch auf anderen Webseiten auf unser
        Angebot aufmerksam zu machen. Ziel ist es, dass unsere Werbekampagnen
        wirklich auch nur jene Menschen erreichen, die sich für unsere Angebote
        interessieren. Mit dem Conversion-Tracking Tool sehen wir welche
        Keywords, Anzeigen, Anzeigengruppen und Kampagnen zu den gewünschten
        Kundenaktionen führen. Wir sehen wie viele Kunden mit unseren Anzeigen
        auf einem Gerät interagieren und dann eine Conversion durchführen. Durch
        diese Daten können wir unseren Kosten-Nutzen-Faktor berechnen, den
        Erfolg einzelner Werbemaßnahmen messen und folglich unsere
        Online-Marketing-Maßnahmen optimieren. Wir können weiters mithilfe der
        gewonnenen Daten unsere Webseite für Sie interessanter gestalten und
        unser Werbeangebot noch individueller an Ihre Bedürfnisse anpassen.
      </p>
      <h4 class="data__subsubheading">
        Welche Daten werden bei Google Ads Conversion-Tracking gespeichert?
      </h4>
      <p>
        Wir haben ein Conversion-Tracking-Tag oder Code-Snippet auf unserer
        Webseite eingebunden, um gewisse User-Aktionen besser analysieren zu
        können. Wenn Sie nun eine unserer Google Ads-Anzeigen anklicken, wird
        auf Ihrem Computer (meist im Browser) oder Mobilgerät das Cookie
        „Conversion“ von einer Google-Domain gespeichert. Cookies sind kleine
        Textdateien, die Informationen auf Ihrem Computer speichern.
      </p>
      <p>
        Hier die Daten der wichtigsten Cookies für das Conversion-Tracking von
        Google:
      </p>
      <p>
        <span class="label">Name:</span> Conversion <br />
        <span class="label">Wert:</span>
        EhMI_aySuoyv4gIVled3Ch0llweVGAEgt-mr6aXd7dYlSAGQ311170952-3 <br />
        <span class="label">Verwendungszweck:</span> Dieses Cookie speichert
        jede Conversion, die Sie auf unserer Seite machen, nachdem Sie über eine
        Google Ad zu uns gekommen sind. <br />
        <span class="label">Ablaufdatum:</span> nach 3 Monaten
      </p>
      <p>
        <span class="label">Name:</span> _gac <br />
        <span class="label">Wert:</span>
        1.1558695989.EAIaIQobChMIiOmEgYO04gIVj5AYCh2CBAPrEAAYASAAEgIYQfD_BwE
        <br />
        <span class="label">Verwendungszweck:</span> Dies ist ein klassisches
        Google Analytics-Cookie und dient dem Erfassen verschiedener Handlungen
        auf unserer Webseite. <br />
        <span class="label">Ablaufdatum:</span> nach 3 Monaten
      </p>
      <p>
        <span class="label">Anmerkung:</span> Das Cookie _gac scheint nur in
        Verbindung mit Google Analytics auf. Die oben angeführte Aufzählung hat
        keinen Anspruch auf Vollständigkeit, da Google für analytische
        Auswertung immer wieder auch andere Cookies verwendet.
      </p>
      <p>
        Sobald Sie eine Aktion auf unserer Webseite abschließen, erkennt Google
        das Cookie und speichert Ihre Handlung als sogenannte Conversion.
        Solange Sie auf unserer Webseite surfen und das Cookie noch nicht
        abgelaufen ist, erkennen wir und Google, dass Sie über unsere
        Google-Ads-Anzeige zu uns gefunden haben. Das Cookie wird ausgelesen und
        mit den Conversion-Daten zurück an Google Ads gesendet. Es ist auch
        möglich, dass noch andere Cookies zur Messung von Conversions verwendet
        werden. Das Conversion-Tracking von Google Ads kann mithilfe von Google
        Analytics noch verfeinert und verbessert werden. Bei Anzeigen, die
        Google an verschiedenen Orten im Web anzeigt, werden unter unserer
        Domain möglicherweise Cookies mit dem Namen “__gads” oder “_gac”
        gesetzt. Seit September 2017 werden diverse Kampagneninformationen von
        analytics.js mit dem _gac-Cookie gespeichert. Das Cookie speichert diese
        Daten, sobald Sie eine unserer Seiten aufrufen, für die die automatische
        Tag-Kennzeichnung von Google Ads eingerichtet wurde. Im Gegensatz zu
        Cookies, die für Google-Domains gesetzt werden, kann Google diese
        Conversion-Cookies nur lesen, wenn Sie sich auf unserer Webseite
        befinden. Wir erheben und erhalten keine personenbezogenen Daten. Wir
        bekommen von Google einen Bericht mit statistischen Auswertungen. So
        erfahren wir beispielsweise die Gesamtanzahl der User, die unsere
        Anzeige angeklickt haben und wir sehen, welche Werbemaßnahmen gut
        ankamen.
      </p>
      <h4 class="data__subsubheading">
        Wie lange und wo werden die Daten gespeichert?
      </h4>
      <p>
        An dieser Stelle wollen wir darauf hinweisen, dass wir keinen Einfluss
        darauf haben, wie Google die erhobenen Daten weiterverwendet. Laut
        Google werden die Daten verschlüsselt und auf sicheren Servern
        gespeichert. In den meisten Fällen laufen Conversion-Cookies nach 30
        Tagen ab und übermitteln keine personenbezogenen Daten. Die Cookies mit
        dem Namen „Conversion“ und „_gac“ (das in Verbindung mit Google
        Analytics zum Einsatz kommt) haben ein Ablaufdatum von 3 Monaten.
      </p>
      <h4 class="data__subsubheading">
        Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
      </h4>
      <p>
        Sie haben die Möglichkeit am Conversion-Tracking von Google Ads nicht
        teilzunehmen. Wenn Sie das Cookie des Google Conversion-Tracking über
        Ihren Browser deaktivieren, blockieren Sie das Conversion-Tracking. In
        diesem Fall werden Sie in der Statistik des Tracking-Tools nicht
        berücksichtigt. Sie können die Cookie-Einstellungen in Ihrem Browser
        jederzeit verändern. Bei jedem Browser funktioniert dies etwas anders.
        Hier finden Sie die Anleitung, wie Sie Cookies in Ihrem Browser
        verwalten:
      </p>
      <a
        href="https://support.google.com/chrome/answer/95647?tid=311170952"
        target="_blank"
        rel="nofollow"
        >Chrome: Cookies in Chrome löschen, aktivieren und verwalten</a
      >
      <br />
      <br />
      <a
        href="https://support.apple.com/de-at/guide/safari/sfri11471/mac?tid=311170952"
        target="_blank"
        rel="nofollow"
        >Safari: Verwalten von Cookies und Websitedaten mit Safari</a
      >
      <br />
      <br />
      <a
        href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen?tid=311170952"
        target="_blank"
        rel="nofollow"
        >Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem
        Computer abgelegt haben</a
      >
      <br />
      <br />
      <a
        href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies?tid=311170952"
        target="_blank"
        rel="nofollow"
        >Internet Explorer: Löschen und Verwalten von Cookies</a
      >
      <br />
      <br />
      <a
        href="https://support.microsoft.com/de-at/help/4027947/microsoft-edge-delete-cookies?tid=311170952"
        target="_blank"
        rel="nofollow"
        >Microsoft Edge: Löschen und Verwalten von Cookies</a
      >
      <br />
      <br />
      <p>
        Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren
        Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie
        gesetzt werden soll. So können Sie bei jedem einzelnen Cookie
        entscheiden, ob Sie das Cookie erlauben oder nicht. Durch das
        Herunterladen und Installieren dieses Browser-Plug-ins auf
        <a
          href="https://support.google.com/ads/answer/7395996"
          target="_blank"
          rel="nofollow"
          >https://support.google.com/ads/answer/7395996</a
        >
        werden ebenfalls alle „Werbecookies“ deaktiviert. Bedenken Sie, dass Sie
        durch das Deaktivieren dieser Cookies nicht die Werbeanzeigen
        verhindern, sondern nur die personalisierte Werbung.
      </p>
      <p>
        Durch die Zertifizierung für das amerikanische-europäische
        Datenschutzübereinkommen “Privacy Shield”, muss der amerikanische
        Konzern Google LLC die in der EU geltenden Datenschutzgesetze einhalten.
        Wenn Sie Näheres über den Datenschutz bei Google erfahren möchten,
        empfehlen wir die allgemeine Datenschutzerklärung von Google:
        <a
          href="https://policies.google.com/privacy?hl=de"
          target="_blank"
          rel="nofollow"
          >https://policies.google.com/privacy?hl=de.</a
        >
      </p>
      <br />
      <p>
        Quelle: Erstellt mit dem Datenschutz Generator von AdSimple in
        Kooperation mit
        <a href="https://www.hashtagbeauty.de" target="_blank" rel="nofollow"
          >hashtagbeauty.de</a
        >
      </p>
    </div>
    <app-footer class="data__footer"></app-footer>
  </div>
</template>

<script>
import AppFooter from "@/components/Templates/Footer.vue";

export default {
  name: "Home",
  components: {
    AppFooter
  }
};
</script>

<style lang="scss" scoped>
@import "sass/main.scss";

.data {
  position: relative;
  padding-bottom: 12rem;
  display: grid;
  grid-template-columns: [margin-left-start] 1fr [margin-left-end center-start] $width-center [center-end margin-right-start] 1fr [margin-right-end];

  color: $color-white;
  font-size: 2.4rem;

  @include respond(tab-land) {
    grid-template-columns: [margin-left-start] 3rem [margin-left-end center-start] 1fr [center-end margin-right-start] 3rem [margin-right-end];
  }

  @include respond(phone) {
    grid-template-columns: [margin-left-start] 1.5rem [margin-left-end center-start] 1fr [center-end margin-right-start] 1.5rem [margin-right-end];

    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    word-break: break-word;
    hyphens: auto;
  }
}

.data__subheading {
  margin-top: 6.25rem;
  margin-bottom: 1.5rem;

  &:first-of-type {
    margin-top: 4rem;
  }
}

.data__subsubheading {
  margin-top: 4rem;
  margin-bottom: 1.5rem;
  font-size: 2.6rem;
}

p {
  margin-bottom: 3rem;
}

ul {
  margin: 2rem 0 2rem 4rem;
}

.label {
  font-family: $font-primary-bold;
  // font-weight: 700;
}

.bold-heading {
  display: block;
  font-family: $font-primary-bold;
  // font-weight: 700;
}

.data__content {
  grid-column: 2 / 3;
  margin-top: 10rem;
  margin-bottom: 10rem;
  width: 100%;
}

a {
  // color: $color-primary-old-light-1;
  color: #7cccff;
}

.data__footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: $color-black;
}
</style>
